import React from 'react';
import LabsHeader from '../../components/layout/labs/header';
import LabsHero from '../../components/layout/labs/hero';
import LabsServices from '../../components/layout/labs/services';
import LabsContact from '../../components/layout/labs/contact';
import LabsFooter from '../../components/layout/labs/footer';
import LabsClients from '../../components/layout/labs/clients';
import LabsSlogan from '../../components/layout/labs/slogan';

const LabsHome: React.FC = () => {
    return (
        <div className="page-container">
            <LabsHeader />
            <LabsHero />
            <LabsServices />
            <LabsSlogan />
            <LabsClients />
            <LabsContact />
            <LabsFooter />
        </div>
    );
};

export default LabsHome;
