import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import logo from '../../../../assets/images/home/logo_white.png';
import './style.css';

const HomeHero: React.FC = () => {
    const { t } = useTranslation();

    return (
        <section className="home-bg-with-overlay text-white py-5">
            <div className="home-bg-overlay"></div>
            <Container>
                <Row className="align-items-center justify-content-center">
                    <Col md="6" className="mb-4 mb-md-0 d-flex flex-column align-items-center text-center">
                        <Card className="border-0 text-white home-card order-2" style={{ backgroundColor: 'transparent' }}>
                            <CardBody>
                                <div className="home-card-content">
                                    <h1 className="display-3">{t('home.hero.title')}</h1>
                                    <p className="lead">{t('home.hero.subtitle')}</p>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="home-hero-image mb-4">
                            <img src={logo} alt="" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default HomeHero;
