import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import {
  // FaFacebook,
  // FaTwitter,
  FaInstagram
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './style.css';

const GamesFooter: React.FC = () => {
    const { t } = useTranslation();

  return (
    <footer className="bg-dark text-white pt-5 pb-2">
      <Container>
        <Row>
          <Col md="4">
            <Card className="border-0 bg-dark text-white">
              <CardBody>
                <h5>{t('games.footer.about.title')}</h5>
                <p>{t('games.footer.about.description')}</p>
                <p>{t('games.footer.about.country')}</p>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="border-0 bg-dark text-white">
              <CardBody>
                <h5>{t('games.footer.contact.title')}</h5>
                <p>{t('games.footer.contact.email')}: worliark@gmail.com</p>
                {/* <p>{t('games.footer.contact.phone')}: +123 456 789</p> */}
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="border-0 bg-dark text-white">
              <CardBody>
                <h5>{t('games.footer.follow')}</h5>
                <div className="games-social-icons">
                  {/* <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                    <FaFacebook className="games-social-icon" />
                  </a>
                  <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                    <FaTwitter className="games-social-icon" />
                  </a> */}
                  <a href="https://www.instagram.com/worliark?igsh=am5vZThkZDk2czll" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="games-social-icon" />
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
            <p className="text-center small">© 2024 Worliark Studios. All rights reserved.</p>
        </Row>
      </Container>
    </footer>
  );
}

export default GamesFooter;
