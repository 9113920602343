import React from 'react';
import gameLogo from '../../../assets/games/squarepuzzle/logo.png';
import './style.css';

const SquarePuzzlePolicy: React.FC = () => {
    return (
        <div className='policy-body'>
            <img className='game-logo' src={gameLogo} alt="Square Puzzle Logo" />

            <h2>Privacy Policy for Square Puzzle</h2>
            <h2>Last Updated: November 28th 2023</h2>

            <h3>Welcome to Square Puzzle!</h3>
            <p>
                This page informs you of our policies regarding the collection, use,
                and disclosure of personal information when you use our service.
            </p>

            <h3>Information We Collect:</h3>
            <p>
                Nickname: When you use Square Puzzle, we collect the nickname you provide to
                appear on the ranking. No other personal information is collected.
            </p>

            <h3>How We Use Your Information:</h3>
            <p>We use the collected nickname to display on the ranking within the app.</p>
            <p>
                No other personal information is collected, and
                we do not use the nickname for any other purpose.
            </p>

            <h3>Sharing of Information:</h3>
            <p>
                We do not share the collected nickname with third parties. It is
                solely used for the purpose of displaying rankings within the app.
            </p>

            <h3>Security:</h3>
            <p>
                The security of your information is important to us. We take all
                reasonable measures to protect the collected nickname.
            </p>

            <h3>User Accounts:</h3>
            <p>
                Square Puzzle does not require users to create an account or log in.
                The only information collected is the nickname provided by the user.
            </p>

            <h3>Your Rights:</h3>
            <p>
                You have the right to choose a nickname and participate in the app's ranking.
                No other personal information is required.
            </p>

            <h3>Changes to this Privacy Policy:</h3>
            <p>
                We may update our Privacy Policy from time to time.
                We recommend reviewing this page periodically for any changes.
            </p>

            <h3>Contact:</h3>
            <p>
                If you have questions about this Privacy Policy,
                please contact us at worliark@gmail.com.
            </p>

            <p>By using Square Puzzle, you agree to the terms of this policy.</p>
        </div>
    );
};

export default SquarePuzzlePolicy;
