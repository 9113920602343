import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    // Button
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import heroImage from '../../../../assets/images/labs/dev-labs.png';
import './style.css';

const LabsHero: React.FC = () => {
    const { t } = useTranslation();

    return (
        <section className="bg-with-overlay text-white py-5">
            <div className="bg-overlay"></div>
            <Container>
                <Row>
                    <Col md="6">
                        <Card className="border-0 text-white labs-card" style={{ backgroundColor: 'transparent' }}>
                            <CardBody>
                                <div className="labs-card-content">
                                    <h1 className="display-3">{t('labs.hero.title')}</h1>
                                    <p className="lead">{t('labs.hero.subtitle')}</p>
                                    {/* <Button color="light">{t('labs.general.learn_more')}</Button> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" className="labs-hero-image">
                        <img src={heroImage} alt="" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LabsHero;
