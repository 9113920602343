import React, { useState } from 'react';
import { Navbar, NavbarBrand, Nav, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarToggler, Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './style.css';

const HomeHeader: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  };

  return (
    <Navbar color="dark" dark expand="md">
      <Container className="navbar-container">
        <NavbarBrand href="/" className="navbar-brand">Worliark Studios</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className={`ml-auto custom-ml-auto ${isOpen ? 'mobile-menu' : ''}`} navbar>
            {/* <NavItem>
              <NavLink href="/">{t('games.header.career')}</NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink href="#home-contact">{t('games.header.contact')}</NavLink>
            </NavItem> */}
            <UncontrolledDropdown nav inNavbar className="mobile-dropdown">
              <DropdownToggle nav caret>
                {i18n.language.toUpperCase()}
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={() => changeLanguage('pt')}>
                  PT
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage('en')}>
                  EN
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage('de')}>
                  DE
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage('es')}>
                  ES
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default HomeHeader;
