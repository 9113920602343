import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import client1Logo from '../../../../assets/images/labs/clients/onagro.svg';
import client2Logo from '../../../../assets/images/labs/clients/design_basso.png';
// import client3Logo from 'caminho/para/logo3.png';
// import client4Logo from 'caminho/para/logo4.png';
import './style.css';

const LabsClients: React.FC = () => {
    const { t } = useTranslation();

  return (
    <section id="clients" className="py-5 bg-dark">
      <Container>
        <h2 className="text-center mb-4">{t('labs.clients.title')}</h2>
        <Row className='d-flex justify-content-center align-items-center'>
          <Col md="3">
            <Card className="border-0 labs-client-card" style={{ backgroundColor: 'transparent' }}>
              <CardBody className="labs-client-content">
                <img src={client1Logo} alt="Client 1" className="img-fluid mx-auto d-block" />
              </CardBody>
            </Card>
          </Col>
          
          <Col md="3">
            <Card className="border-0 labs-client-card" style={{ backgroundColor: 'transparent' }}>
              <CardBody className="labs-client-content">
                <img src={client2Logo} alt="Client 1" className="img-fluid mx-auto d-block" />
              </CardBody>
            </Card>
          </Col>
          
          {/* <Col md="3">
            <Card className="border-0 labs-client-card" style={{ backgroundColor: 'transparent' }}>
              <CardBody className="labs-client-content">
                <img src={client1Logo} alt="Client 1" className="img-fluid mx-auto d-block" />
              </CardBody>
            </Card>
          </Col> */}

          {/* <Col md="3">
            <Card className="border-0 labs-client-card" style={{ backgroundColor: 'transparent' }}>
              <CardBody className="labs-client-content">
                <img src={client1Logo} alt="Client 1" className="img-fluid mx-auto d-block" />
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
}

export default LabsClients;
