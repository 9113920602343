import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css'; 

const LabsSlogan: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="labs-slogan-section">
            <h2>{t('labs.slogan')}</h2>
        </div>
    );
};

export default LabsSlogan;
