import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home';
import GamesHome from '../pages/games';
import LabsHome from '../pages/labs';
import SquarePuzzlePolicy from '../pages/policies/squarePuzzle';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const getSavedLanguage = () => {
  return localStorage.getItem('selectedLanguage');
};

const AppRouter = () => {
  const { i18n } = useTranslation();
  
  const savedLanguage = getSavedLanguage();

  useEffect(() => {
    if (savedLanguage && i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [savedLanguage, i18n]);

  return (
    <Routes>
      {/* Company */}
      <Route index element={<HomePage />} />
      <Route path="/" element={<HomePage />} />

      {/* Games */}
      <Route path="/games" element={<GamesHome />} />

      {/* Labs */}
      <Route path="/labs" element={<LabsHome />} />

      {/* Policies */}
      <Route path="/squarepuzzle/policy" element={<SquarePuzzlePolicy />} />
    </Routes>
  );
};

export default AppRouter;
