import React, { useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarToggler, Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './style.css';

const GamesHeader: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  };

  return (
    <Navbar color="dark" dark expand="md">
      <Container className="navbar-container">
        <NavbarBrand href="/games" className="navbar-brand">Worliark Games</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className={`ml-auto custom-ml-auto ${isOpen ? 'mobile-menu' : ''}`} navbar>
            <NavItem>
              <NavLink href="/">{t('games.header.home')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#services">{t('games.header.services')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#portfolio">{t('games.header.portfolio')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#games-contact">{t('games.header.contact')}</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar className="mobile-dropdown">
              <DropdownToggle nav caret>
                {i18n.language.toUpperCase()}
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={() => changeLanguage('pt')}>
                  PT
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage('en')}>
                  EN
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage('de')}>
                  DE
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage('es')}>
                  ES
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default GamesHeader;
