import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import heroImage from '../../../../assets/images/games/dev-games.png';
import './style.css';

const GamesHero: React.FC = () => {
    const { t } = useTranslation();

    return (
        <section className="games-bg-with-overlay text-white py-5">
            <div className="games-bg-overlay"></div>
            <Container>
                <Row>
                    <Col md="6">
                        <Card className="border-0 text-white games-card" style={{ backgroundColor: 'transparent' }}>
                            <CardBody>
                                <div className="games-card-content">
                                    <h1 className="display-3">{t('games.hero.title')}</h1>
                                    <p className="lead">{t('games.hero.subtitle')}</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" className="games-hero-image">
                        <img src={heroImage} alt="" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default GamesHero;
