import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import {
  FaGooglePlay,
  FaApple,
  FaXbox,
  FaPlaystation,
  FaSteam
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import spIcon from '../../../../assets/images/games/portfolio/android/square_puzzle_icon.png';
import './style.css';

interface Game {
  title: string;
  description: string;
  image: string;
  link: string;
}

const GamesPortfolio: React.FC = () => {
    const { t } = useTranslation();


  const platforms = [
    { name: 'Android', icon: <FaGooglePlay /> },
    { name: 'iOS', icon: <FaApple /> },
    { name: 'Windows', icon: <FaSteam /> },
    { name: 'Xbox', icon: <FaXbox /> },
    { name: 'PlayStation', icon: <FaPlaystation /> },
  ];

  const gamesByPlatform: Record<string, Game[]> = {
    Android: [
      {
        title: t('games.portfolio.square_puzzle.title'),
        description: t('games.portfolio.square_puzzle.description'),
        image: spIcon,
        link: 'https://play.google.com/store/apps/details?id=com.worliark.squarepuzzle'
      },
    //   {
    //     title: 'Game 2',
    //     description: 'Breve descrição do jogo aqui...',
    //     image: spIcon,
    //     link: 'game2-link-url'
    //   },
    //   {
    //     title: 'Game 3',
    //     description: 'Breve descrição do jogo aqui...',
    //     image: spIcon,
    //     link: 'game2-link-url'
    //   }
    ],
    iOS: [],
    Windows: [],
    macOS: [],
    Linux: [],
    Xbox: [],
    PlayStation: [],
  };

  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(platforms[0].name);

  const handlePlatformClick = (platform: string) => {
    setSelectedPlatform(platform);
  };

  return (
    <section id="portfolio" className="py-5 bg-dark">
      <Container>
        <h2 className="text-center text-white mb-4">Portfolio</h2>
        <Row>
          {platforms.map((platform) => (
            <Col key={platform.name}>
              <Card
                className={`border-0 games-platform-btn ${selectedPlatform === platform.name ? 'selected' : ''}`}
                style={{ backgroundColor: 'transparent' }}
                onClick={() => handlePlatformClick(platform.name)}
              >
                <CardBody>
                  {platform.icon}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        {selectedPlatform && (
          <Row className="mt-4 d-flex justify-content-center align-items-center">
            {gamesByPlatform[selectedPlatform].length > 0 ? (
              gamesByPlatform[selectedPlatform].map((game) => (
                <Col key={game.title} md="4" className="mb-4 d-flex justify-content-center align-items-center">
                  <Card className={`border-0 games-portfolio-card`}>
                    <CardBody className="games-portfolio-card-content">
                      <div className="imgBx">
                        <img src={game.image} alt={game.title} />
                      </div>
                      <div className="contentBx">
                        <h3>{game.title}</h3>
                        <p>{game.description}</p>
                        <a href={game.link} target="_blank" rel="noopener noreferrer">
                          <button className="btn btn-warning download-btn">Download</button>
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <Col md="4" className="mb-4 d-flex justify-content-center align-items-center">
                <Card className={`border-0 games-portfolio-card`}>
                  <CardBody className="games-portfolio-card-content">
                    <div className="contentBx text-center">
                      <h3>{t('games.portfolio.coming.title')}</h3>
                      <p>{t('games.portfolio.coming.description')}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </section>
  );
};

export default GamesPortfolio;
