import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGamepad, FaCode } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import HomeHeader from '../../components/layout/home/header';
import HomeFooter from '../../components/layout/home/footer';
import HomeHero from '../../components/layout/home/hero';
import './style.css';

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="page-container bg-dark">
            <HomeHeader />

            <HomeHero />

            <h2 className="path-title">{t('home.path')}</h2>
            <div className="home-cards-container">
                <div className="home-card-box" onClick={() => navigate('/games')}>
                    <span></span>
                    <div className="home-card-content">
                        <FaGamepad className="home-card-icon" />
                        <h2>Worliark Games</h2>
                        <p>{t('home.games-slogan')}</p>
                    </div>
                </div>

                <div className="home-card-box second-card" onClick={() => navigate('/labs')}>
                    <span></span>
                    <div className="home-card-content">
                        <FaCode className="home-card-icon" />
                        <h2>Worliark Labs</h2>
                        <p>{t('home.labs-slogan')}</p>
                    </div>
                </div>
            </div>
        
            <HomeFooter />
        </div>
    );
};

export default HomePage;
