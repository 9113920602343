import React from 'react';
import GamesHeader from '../../components/layout/games/header';
import GamesHero from '../../components/layout/games/hero';
import GamesServices from '../../components/layout/games/services';
import GamesContact from '../../components/layout/games/contact';
import GamesFooter from '../../components/layout/games/footer';
import GamesSlogan from '../../components/layout/games/slogan';
import GamesPortfolio from '../../components/layout/games/portfolio';

const GamesHome: React.FC = () => {
    return (
        <div className="page-container">
            <GamesHeader />
            <GamesHero />
            <GamesServices />
            <GamesSlogan />
            <GamesPortfolio />
            <GamesContact />
            <GamesFooter />
        </div>
    );
};

export default GamesHome;
