import React from 'react';
import AppRouter from './routes';

function App() {
  return (
    <AppRouter />
  );
}

export default App;

