import React from 'react';
import { FaCode, FaCog, FaPalette } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './style.css';

const LabsServices = () => {
    const { t } = useTranslation();

  return (
    <section id="services" className="bg-dark">
        <h2 className="text-center pt-5 text-white">{t('labs.services.title')}</h2>
        <div className="labs-services-container">
            <div className="labs-service-box">
                <span></span>
                <div className="labs-service-content">
                    <FaCode className="labs-service-icon" />
                    <h2>{t('labs.services.dev.title')}</h2>
                    <p>{t('labs.services.dev.description')}</p>
                </div>
            </div>

            <div className="labs-service-box">
                <span></span>
                <div className="labs-service-content">
                    <FaCog className="labs-service-icon" />
                    <h2>{t('labs.services.consult.title')}</h2>
                    <p>{t('labs.services.consult.description')}</p>
                </div>
            </div>

            <div className="labs-service-box">
                <span></span>
                <div className="labs-service-content">
                    <FaPalette className="labs-service-icon" />
                    <h2>{t('labs.services.ux.title')}</h2>
                    <p>{t('labs.services.ux.description')}</p>
                </div>
            </div>
        </div>
    </section>
  );
}

export default LabsServices;
