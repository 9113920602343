import React, { useRef } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import './style.css';

const GamesContact: React.FC = () => {
    const { t } = useTranslation();
    const form = useRef<HTMLFormElement>(null);

    const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            if (form.current) {
                const formData = new FormData(form.current);
                formData.append('page', 'Worliark Games');

                const pageInput = document.createElement('input');
                pageInput.type = 'hidden';
                pageInput.name = 'page';
                pageInput.value = 'Worliark Games';
                form.current.appendChild(pageInput);

                await emailjs.sendForm(
                    'service_pvqw6g8',
                    'template_bz1nijw',
                    form.current,
                    'IOpICTDWe3hDaHMZc'
                );

                toast.success('E-mail sent successfully!');
                form.current.reset();
            }
        } catch (error) {
            toast.error('Failed to send e-mail. Please try again later.');
        }
    };

    return (
        <section id="games-contact" className="py-5">
            <div className="games-bg-overlay" />
            <Container>
                <Row>
                    <Col md="6">
                        <div className="games-left-content p-4">
                            <h2 className="text-white">{t('games.contact.cta.title')}</h2>
                            <p className="text-white">{t('games.contact.cta.message')}</p>
                        </div>
                    </Col>
                    <Col md="6">
                        <Card className="p-4 border-0 bg-dark text-white">
                            <CardBody>
                                <h2>{t('games.contact.form.title')}</h2>
                                <form ref={form} onSubmit={sendEmail}>
                                    <FormGroup>
                                        <Label for="name">{t('games.contact.form.name')}</Label>
                                        <Input type="text" name="user_name" id="name" placeholder="Your Name" className="bg-dark text-white" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="email">{t('games.contact.form.email')}</Label>
                                        <Input type="email" name="user_email" id="email" placeholder="Your Email" className="bg-dark text-white" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="message">{t('games.contact.form.message')}</Label>
                                        <Input type="textarea" name="message" id="message" placeholder="Your Message" className="bg-dark text-white" />
                                    </FormGroup>
                                    <Button type="submit" color="warning" className="w-100">{t('labs.general.submit')}</Button>
                                    {/* <input type="submit" value="Send" className="btn btn-warning w-100" /> */}
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer theme="dark" />
        </section>
    );
}

export default GamesContact;
