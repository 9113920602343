import React from 'react';
import { FaCube, FaGamepad, FaPalette } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './style.css';

const LabsServices = () => {
    const { t } = useTranslation();

  return (
    <section id="services" className="bg-dark">
        <h2 className="text-center pt-5 text-white">{t('games.services.title')}</h2>
        <div className="games-services-container">
            <div className="games-service-box">
                <span></span>
                <div className="games-service-content">
                    <FaPalette className="games-service-icon" />
                    <h2>{t('games.services.art.title')}</h2>
                    <p>{t('games.services.art.description')}</p>
                </div>
            </div>

            <div className="games-service-box">
                <span></span>
                <div className="games-service-content">
                    <FaGamepad className="games-service-icon" />
                    <h2>{t('games.services.dev.title')}</h2>
                    <p>{t('games.services.dev.description')}</p>
                </div>
            </div>

            <div className="games-service-box">
                <span></span>
                <div className="games-service-content">
                    <FaCube className="games-service-icon" />
                    <h2>{t('games.services.3d.title')}</h2>
                    <p>{t('games.services.3d.description')}</p>
                </div>
            </div>
        </div>
    </section>
  );
}

export default LabsServices;
